<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="requestEditForm">
        <c-card title="밀폐공간 작업장비 대여 신청" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline>
              <c-btn 
                v-show="editable && Boolean(param.giveEquipment.heaClosedSpaceEquipmentGiveId) && !disabled" 
                :url="completeUrl"
                :isSubmit="isComplete"
                :param="param.giveEquipment"
                mappingType="PUT"
                label="대여 신청" 
                icon="check"
                @beforeAction="completeData"
                @btnCallback="completeCallback" />
              <c-btn 
                v-show="editable && !disabled" 
                label="저장" 
                :url="saveUrl"
                :isSubmit="isSaveRequest"
                :param="param.giveEquipment"
                :mappingType="saveType"
                icon="save" 
                @beforeAction="saveRequest"
                @btnCallback="saveCallback" />
              <c-btn 
                v-show="editable && Boolean(param.giveEquipment.heaClosedSpaceEquipmentGiveId) && !disabled" 
                label="삭제" 
                icon="delete_forever" 
                @btnClicked="remove" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-3">
              <c-plant
                :required="true"
                :editable="editable && !disabled"
                type="edit"
                name="plantCd"
                v-model="param.giveEquipment.plantCd"
              />
            </div>
            <div class="col-3">
              <c-field
                :required="true"
                label='신청자'
                :editable="editable && !disabled"
                :data="param.giveEquipment"
                type="dept_user"
                name="regUserId"
                v-model="param.giveEquipment.regUserId"
              />
            </div>
            <!-- <div class="col-3">
              <c-select
                :required="true"
                :editable="editable && !disabled"
                codeGroupCd="CLOSED_SPACE_TYPE_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                label="밀폐공간"
                name="spaceName"
                v-model="param.giveEquipment.spaceNameCd">
              </c-select>
            </div> -->
            <div class="col-3">
              <c-text
                :afterIcon="disabled ? null :[
                  { name: 'search', click: true, callbackName: 'searchClosedSpace'},
                  //{ name: 'close', click: true, callbackName: 'removeClosedSpace' }
                ]"
                :required="true" 
                :readonly="true"
                :close="true"
                :editable="editable"
                label="밀폐공간"
                name="spaceNameName"
                @searchClosedSpace="searchClosedSpace"
                v-model="param.giveEquipment.spaceNameName">
              </c-text>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="tableRequest"
        :editable="editable && !disabled"
        title="장비 대여신청 목록"
        tableId="table"
        :columns="gridReceive.columns"
        :data="param.giveEquipment.closedSpaceEquipmentGiveItemModels"
        rowKey="heaClosedSpaceEquipmentGiveItemId"
        :merge="gridReceive.merge"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        gridHeight="670px"
        selection="multiple"
      >
        <template slot="table-button">
          <div>
            <q-btn-group outline>
              <c-btn v-show="editable && !disabled" label="추가" icon="add" @btnClicked="addEquips" />
              <c-btn v-show="editable && !disabled" label="제외" icon="remove" @btnClicked="removeRequest" />
            </q-btn-group>
          </div>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'closedSpaceEquipApply',
  props: {
    param: {
      type: Object,
      default: () => ({
        heaClosedSpaceEquipmentGiveId: '',
        giveEquipment: {
          heaClosedSpaceEquipmentGiveId: '',
          plantCd: '',
          requestDate: '',
          regUserId: '',
          spaceNameCd: null,
          spaceNameName: '',
          giveStatusCd: '',
          requestStepCd: '',
          closedSpaceEquipmentGiveItemModels: [],
          deleteClosedSpaceEquipmentGiveItemModels: [],
          closedSpaceEquipmentReturnItemModels: [],
          deleteClosedSpaceEquipmentReturnItemModels: [],
        },
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      gridReceive: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '장비종류',
            style: 'width:200px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '장비명',
            style: 'width:60px',
            align: 'center',
            sortable: false,
          },
          {
            required: true,
            name: 'equipmentNumber',
            field: 'equipmentNumber',
            label: '신청수량',
            style: 'width:60px',
            align: 'right',
            type: 'number',
            sortable: false,
          },
          {
            required: true,
            name: 'requestDate',
            field: 'requestDate',
            label: '신청일',
            style: 'width:80px',
            align: 'center',
            type: 'date',
            sortable: false,
          },
          {
            required: true,
            name: 'rentPeriod',
            field: 'rentPeriod',
            label: '대여예정기간',
            style: 'width:80px',
            align: 'center',
            range: true,
            type: 'date',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            type: 'text',
            style: 'width:250px',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      saveType: 'POST',
      isSaveRequest: false,
      isComplete: false,
      saveUrl: '',
      deleteUrl: '',
      completeUrl: '',
    };
  },
  computed: {
    disabled() {
      return this.param.giveEquipment.requestStepCd !== 'CSE0000001' && Boolean(this.param.heaClosedSpaceEquipmentGiveId)
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.hea.cse.gives.insert.url;
      this.deleteUrl = transactionConfig.hea.cse.gives.delete.url;
      this.completeUrl = transactionConfig.hea.cse.gives.complete.url;
      // code setting
      // list setting
      
    },
    /* eslint-disable no-unused-vars */
    saveRequest() {
      if (this.param.heaClosedSpaceEquipmentGiveId) {
        this.saveUrl = transactionConfig.hea.cse.gives.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.hea.cse.gives.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['requestEditForm'].validate().then(_result => {
        if (this.$comm.validTable(this.gridReceive.columns, this.param.giveEquipment.closedSpaceEquipmentGiveItemModels)) {
          if (_result) { 
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.param.giveEquipment.regUserId = this.$store.getters.user.userId
                this.param.giveEquipment.chgUserId = this.$store.getters.user.userId   
                
                  this.$_.forEach(this.param.giveEquipment.closedSpaceEquipmentGiveItemModels, _item => {
                    if (_item.rentPeriod && _item.rentPeriod.length > 0) {
                      _item.rentStartDate = _item.rentPeriod[0]
                      _item.rentEndDate = _item.rentPeriod[1]
                    }
                  })
                
                this.isSaveRequest = !this.isSaveRequest;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.param.heaClosedSpaceEquipmentGiveId) {
        this.$set(this.param, 'heaClosedSpaceEquipmentGiveId', result.data)
      }
      this.$emit('emitStep', {
        name: 'setHeaClosedSpaceEquipmentGiveId',
        param: this.param.heaClosedSpaceEquipmentGiveId,
      })
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.heaClosedSpaceEquipmentGiveId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('emitStep', {
              name: 'closePopup',
              param: {},
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeData() {
      this.$refs['requestEditForm'].validate().then(_result => {
        if (this.$comm.validTable(this.gridReceive.columns, this.param.giveEquipment.closedSpaceEquipmentGiveItemModels)) {
          if (_result) { 
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '대여 신청하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.param.giveEquipment.chgUserId = this.$store.getters.user.userId   

                this.$_.forEach(this.param.giveEquipment.closedSpaceEquipmentGiveItemModels, _item => {
                  if (_item.rentPeriod && _item.rentPeriod.length > 0) {
                    _item.rentStartDate = _item.rentPeriod[0]
                    _item.rentEndDate = _item.rentPeriod[1]
                  }
                })

                this.isComplete = !this.isComplete
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('emitStep', {
        name: 'getDetail',
        param: { 
          heaClosedSpaceEquipmentGiveId: this.param.heaClosedSpaceEquipmentGiveId
        },
      })
    },
    removeRequest() {
      let selectData = this.$refs["tableRequest"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내",
          message: "선택된 항목이 없습니다.", 
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.param.giveEquipment.deleteClosedSpaceEquipmentGiveItemModels) {
            this.param.giveEquipment.deleteClosedSpaceEquipmentGiveItemModels = [];
          }
          if (this.$_.findIndex(this.param.giveEquipment.deleteClosedSpaceEquipmentGiveItemModels, {
            heaClosedSpaceEquipmentGiveItemId: item.heaClosedSpaceEquipmentGiveItemId,
              }) === -1 
            && item.editFlag !== "C"
          ) {
            this.param.giveEquipment.deleteClosedSpaceEquipmentGiveItemModels.push(item);
          }
          this.param.giveEquipment.closedSpaceEquipmentGiveItemModels = this.$_.reject(this.param.giveEquipment.closedSpaceEquipmentGiveItemModels, item);
        });
      }
    },
    addEquips() {
      this.popupOptions.title = '장비목록 팝업';
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/hea/closedSpaceEquipStockPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeStockPopup;
    },
    closeStockPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.param.giveEquipment.closedSpaceEquipmentGiveItemModels) {
          this.param.giveEquipment.closedSpaceEquipmentGiveItemModels = [];
        }
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.param.giveEquipment.closedSpaceEquipmentGiveItemModels, { heaClosedSpaceEquipmentId: item.heaClosedSpaceEquipmentId }) === -1) {
            this.param.giveEquipment.closedSpaceEquipmentGiveItemModels.push({
              heaClosedSpaceEquipmentGiveId: this.param.heaClosedSpaceEquipmentGiveId,
              heaClosedSpaceEquipmentGiveItemId: uid(),
              heaClosedSpaceEquipmentId: item.heaClosedSpaceEquipmentId,  // 재고번호
              equipmentName: item.equipmentName,
              equipmentNumber: null,  // 신청수량
              requestDate: this.$comm.getToday(),
              equipmentTypeName: item.equipmentTypeName,
              regUserId: this.$store.getters.user.userId, 
              editFlag: 'C',
            })
          }
        })
      }
    },
    searchClosedSpace() {
      this.popupOptions.title = '밀폐공간 상세';
      this.popupOptions.param = {
        type: 'single',
      };
      this.popupOptions.target = () => import(`${'./closedSpaceMapPop.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closedSpaceMapPop;
    },
    closedSpaceMapPop(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
        this.param.giveEquipment.spaceNameCd = item.closedSpaceTypeCd
        this.param.giveEquipment.spaceNameName = item.closedSpaceTypeName
        })
      }
    },
    // removeClosedSpace() {
    //   this.param.giveEquipment = {
    //     spaceNameCd: null,
    //     spaceNameName: '',
    //   }
    // },
  },
};
</script>
